<template>
  <div class="card">
    <Steps :model="items" :readonly="true"/>
  </div>


  <Card>
    <template #header>

    </template>
    <template #title>
      Porcentajes
    </template>
    <template v-slot:subtitle>
      Añadir agencia
    </template>
    <template #content>

      <div class="flex justify-content-center">
        <!--        <form @submit.prevent="saveData" class="p-fluid" autocomplete="off" style="width: 70%;">-->

        <Accordion style="width: 100%;" multiple activeIdex="0">
          <AccordionTab header="Loterîa">

            <Dropdown v-model="selectedPaymentProfile"
                      :options="paymentProfiles"
                      optionLabel="tperfil_pago_premios"
                      optionValue="id_perfil_premiosxprocentajes"
                      style="width: 100%; margin-bottom: 10px;"
                      :onClick="loadData"
                      placeholder="Seleccionar perfil de pago"/>

            <DataTable :value="percentages && percentages.loteria" editMode="row" dataKey="id"
                       v-model:editingRows="editingRows"
                       @row-edit-save="onRowEditSaveLoteria" responsiveLayout="scroll"
                       :loading="loadingTable"
                       class="p-datatable-gridlines p-datatable-striped p-datatable-sm editable-cells-table">
              <Column field="tperfil_pago_premios" header="Perfil de pago" style="text-align: left;"/>
              <Column field="tlista" header="Lista" style="text-align: left;"/>
              <Column field="nporcentaje_comision_agc" header="Porcentaje" style="text-align: center;">
                <template #body="{data}">
                  {{ formatNumber(data.nporcentaje_comision_agc) }}%
                </template>
                <template #editor="{ data, field }">
                  <InputNumber v-model="data[field]" suffix="%" :min="0" :max="40" showButtons/>
                </template>
              </Column>
              <Column :rowEditor="true" style="width:10%; min-width:8rem" bodyStyle="text-align:center"></Column>
            </DataTable>
          </AccordionTab>
          <AccordionTab header="Animalitos">
            <DataTable :value="percentages && percentages.animalitos" editMode="row" dataKey="id"
                       v-model:editingRows="editingRows"
                       @row-edit-save="onRowEditSaveAnimalitos" responsiveLayout="scroll"
                       :loading="loadingTable"
                       class="p-datatable-gridlines p-datatable-striped p-datatable-sm editable-cells-table">
              <Column field="tperfil_pago_premios" header="Perfil de pago" style="text-align: left;"/>
              <Column field="tlista" header="Lista" style="text-align: left;"/>
              <Column field="nporcentaje_comision_agc" header="Porcentaje" style="text-align: center;">
                <template #body="{data}">
                  {{ formatNumber(data.nporcentaje_comision_agc) }}%
                </template>
                <template #editor="{ data, field }">
                  <InputNumber v-model="data[field]" suffix="%" :min="0" :max="40" showButtons/>
                </template>
              </Column>
              <Column :rowEditor="true" style="width:10%; min-width:8rem" bodyStyle="text-align:center"></Column>
            </DataTable>
          </AccordionTab>
        </Accordion>

        <!--        </form>-->
      </div>

      <div class="flex justify-content-end" style="margin-top: 10px;">
        <Button
            class="p-button-raised" icon="pi pi-save"
            :onClick="saveData"
            :disabled="!bDirty"
        />
      </div>
    </template>
    <template #footer>

      <div class="grid grid-nogutter justify-content-between">
        <!--        <Button label="Anterior" @click="prevPage()" icon="pi pi-angle-left"/>-->
        <!--        <Button label="Siguiente"-->
        <!--                @click="nextPage()"-->
        <!--                icon="pi pi-angle-right"-->
        <!--                iconPos="right"-->
        <!--                :disabled="this.selectedMunicipio ? disabled : ''"-->
        <!--        />-->
      </div>
    </template>
  </Card>
  <Toast/>
</template>

<script>
import generalMixin from "@/mixins/generalMixin";
import MainService from "@/service/backend/MainService";
import AgencyService from "@/service/backend/AgencyService";

export default {
  name: "Step4",
  mixins: [generalMixin],
  components: {},

  data() {
    return {
      items: [
        {
          label: 'Nombre',
          to: '/agencias/agregar-1'
        },
        {
          label: 'Cadena de comercialización',
          to: '/agencias/agregar-2'
        },
        {
          label: 'Dirección',
          to: '/agencias/agregar-3'
        },
        {
          label: 'Porcentajes',
          to: '/agencias/agregar-4'
        }
      ],
      pageIndex: 2,
      paymentProfiles: null,
      selectedPaymentProfile: null,
      percentages: null,
      loadingTable: false,
      editingRows: [],
      bDirty: false,
      idAgency: null,
      active: 0,
    }
  },
  props: {},
  created() {
    this.$primevue.config.locale.emptyMessage = "Buscando opciones...";
    this.mainService = new MainService;
    this.agencyService = new AgencyService;
  },
  async mounted() {
    // if (this.$store.getters.getAgency.id_agencia !== null) {
    //   this.$toast.add({
    //     severity: 'success',
    //     summary: '',
    //     detail: `Agencia ${this.$store.getters.getAgency.agencyName} creada. | ID: ${this.$store.getters.getAgency.id_agencia}`,
    //     life: 3000
    //   });
    // }

    await this.loadPaymentProfiles();
    await this.loadData();
  },
  methods: {
    async loadPaymentProfiles() {
      this.paymentProfiles = await this.agencyService.getPaymentProfile({
        id_comercializador: this.$store.getters.getProfile.id_comercializador,
        id_banca: this.$store.getters.getProfile.id_banca,
        id_distribuidor: this.$store.getters.getProfile.id_distribuidor,
        id_agencia: this.idAgency,
      });
    },
    async loadData() {
      this.percentages = await this.agencyService.getAgencyPercentages({
        id_comercializador: this.$store.getters.getAgency.id_comercializador,
        id_banca: this.$store.getters.getAgency.id_banca,
        id_distribuidor: this.$store.getters.getAgency.id_distribuidor,
        id_perfil_premiosxprocentajes: this.selectedPaymentProfile,
        id_agencia: this.$store.getters.getAgency.id_agencia,
      });
      this.selectedPaymentProfile = this.paymentProfiles[0].id_perfil_premiosxprocentajes;
    },

    onRowEditSaveLoteria(event) {
      this.bDirty = true;
      let {newData, index} = event;
      this.percentages.loteria[index] = newData;
      // this.saveData();
    },
    onRowEditSaveAnimalitos(event) {
      this.bDirty = true;
      let {newData, index} = event;
      this.percentages.animalitos[index] = newData;
      // this.saveData();
    },

    async saveData() {
      let res = [];
      this.percentages.loteria.forEach((item) => {
        switch (item.id_lista) {
          case 0: // Terminales
            res.ter = Number(item.nporcentaje_comision_agc);
            break;
          case 1: // Triples
            res.tri = Number(item.nporcentaje_comision_agc);
            break;
          case 2: // Terminales con signo
            res.teresp = Number(item.nporcentaje_comision_agc);
            break;
          case 3: // Triples con signo
            res.triesp = Number(item.nporcentaje_comision_agc);
            break;
        }
      });

      const response = await this.agencyService.updateAgencyPercentages({
        // Loteria
        id_psxcmxbanxdisxagc: this.percentages.loteria[0].id_psxcmxbanxdisxagc,
        ter: res.ter,
        tri: res.tri,
        teresp: res.teresp,
        triesp: res.triesp,
        // Animalitos
        nporcentaje_comision_agc: Number(this.percentages.animalitos[0].nporcentaje_comision_agc),
        id_prestador_servicio: 100,
        id_comercializador: this.$store.getters.getAgency.id_comercializador,
        id_banca: this.$store.getters.getAgency.id_banca,
        id_distribuidor: this.$store.getters.getAgency.id_distribuidor,
        id_perfil_premiosxprocentajes: this.selectedPaymentProfile,
        id_agencia: this.$store.getters.getAgency.id_agencia,
      });

      if (response.error) {

        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: `No se pudo editar los porcentajes de la agencia ${this.$store.getters.getAgency.tagencia}| ` + response.error,
          life: 10000
        });
        this.overlay = false;
      } else {
        // Get back to the root
        this.$toast.add({
          severity: 'success',
          summary: '',
          detail: `Porcentajes de la agencia ${this.$store.getters.getAgency.tagencia}| editados.`,
          life: 3000
        });
        await this.wait(5000);
        this.$router.push({name: 'agencias',});
      }
    },
    nextPage() {
      this.$router.push('/agencias/agregar-5');
    },
    prevPage() {
      this.$router.push('/agencias/agregar-3');
    },
  }
}
</script>

<style scoped lang="scss">

.filterItem {
  margin: 20px 0 5px 0;
}

.content {
  width: 80%;
}

.p-float-label {
  width: 100%;
}
</style>